import "core-js/stable";
import "regenerator-runtime/runtime";
import ResponsiveAutoHeight from "responsive-auto-height";
import { initScrollReveal } from "./scroll-reveal";
import GLightbox from "glightbox"; 
import Accordion from "accordion-js";   
import Stickyfill from "stickyfilljs";
import { getParentElementWithTagAndClass } from "./utilities";

/*==========================================
 Variables
===========================================*/

const htmlElement = document.querySelector("html");
const bodyElement = document.body;
const headerElement = document.querySelector("header#o-header");
const siteOverlayElement = document.querySelector("#a-site-overlay");
const preLoaderOverlayElement = document.querySelector("#a-pre-load-overlay");

/*==========================================
 Click Events Listeners
===========================================*/
//use window.scrollY
var scrollpos = window.scrollY;
var header = document.querySelector("header");

function add_class_on_scroll() {
    header.classList.add("v-show-mobile");
}

function remove_class_on_scroll() {
    header.classList.remove("v-show-mobile");
}

window.addEventListener('scroll', function(){ 
   
    scrollpos = window.scrollY;

    if(scrollpos > 200){
        add_class_on_scroll();
    }
    else {
        remove_class_on_scroll();
    }
 
});  

/*==========================================
 Init plugins
===========================================*/
/*==========================================
 GLightbox
===========================================*/

const lightbox = GLightbox({
    selector: ".js-lightbox-play"
}); 

/*==========================================
Sticky-polifills
===========================================*/

var elements = document.querySelectorAll(".sticky");
Stickyfill.add(elements); 

/*==========================================
ACCORDIAN
===========================================*/
//CHECK IF ELEMENT EXISTS ON PAGE AND GETTING ALL LIST OF THEM

const AccordionElement = [].slice.call(
    document.querySelectorAll(".js-accordions")
); 

//console.log("accordians", AccordionElement);
//NEED TO ADD COUNTER FOR THEM
//!dont forget counter in csharp too
var i;
//console.log("accordian-lenght",AccordionElement.length);

let accordions = [];
//console.log("acordion-empty", accordions);
for (i = 0; i < AccordionElement.length; i++) {
    accordions.push(`.js-accordion-${i}`);
}
//console.log("acordion arrway", accordions);
if (accordions.length > 0) {
    new Accordion(accordions); 
}

//SET DEFAULT SETTING => First open
const AccordionElementSecond = [].slice.call(
    document.querySelectorAll(".js-second-accordion")
);
//console.log("accordians", AccordionElementSecond ); 
var e;
let accordionsSecond = [];

for (e = 0; e < AccordionElementSecond.length; e++) {
    accordionsSecond.push(`.js-accordion-${e}`);
}
if (accordionsSecond.length > 0) {
    new Accordion(accordionsSecond,{
        duration: 400,
        showMultiple: true,
       openOnInit:[0]
    }); 
} 
if (window.location.hash) {
    // Fragment exists
    var hash = window.location.hash.substring(1); 
    //opens accordion
   document.querySelector('#' + hash).querySelector('.ac-trigger').click();      
   var openedAccordion = document.querySelector('#' + hash).querySelector('.ac-trigger');

   if (hash) { 
    setTimeout(() => { 
        var headerOffset = 300;
        var elementPosition = openedAccordion.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }, 500);  
}

}

/*==========================================
Pre Loader
===========================================*/

const ready = fn => {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
};

const hidePreloader = () => {
    preLoaderOverlayElement.classList.add("v-hidden");
    htmlElement.style.fontSize = null;

    setTimeout(() => {
        initScrollReveal();

        setTimeout(() => {
            preLoaderOverlayElement.classList.add("display-none");
        }, 200);
    }, 400);
};

setTimeout(() => {
    ready(hidePreloader);
}, 300);

/*==========================================
Equal Height
===========================================*/

const runMatchHeight = () => {
    const elements = document.querySelectorAll(".js-auto-height");
    if (elements && elements.length) {
        const m = new ResponsiveAutoHeight(".js-auto-height");
    }
    if (document.querySelector(".help-content-block")) {
        new ResponsiveAutoHeight(".help-content-block h4");
        new ResponsiveAutoHeight(".help-content-block span.a-lead-type");
    }
};

runMatchHeight();


